/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MobileTicketingEvent { 
    EventId?: number;
    CustomerNumber?: string | null;
    EventName?: string | null;
    EventTypeName?: string | null;
    IsMatchDay?: boolean;
    TicketCount?: number;
    NameHomeTeam?: string | null;
    NameAwayTeam?: string | null;
    EventStartDateTime?: Date | null;
    EventStartDateTimeUtc?: Date | null;
    EventEndDateTime?: Date | null;
    EventEndDateTimeUtc?: Date | null;
    HomeImageUrl?: string | null;
    AwayImageUrl?: string | null;
    EventTypeLogoUrl?: string | null;
    ResellSingleTicketsAllowed?: boolean;
    AdditionalAttribute?: string | null;
}

