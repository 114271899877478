/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MarketPlaceStatus { 
    AvailableForSale?: boolean;
    CurrentlyForSale?: boolean;
    EventPlacementId?: number;
    Price?: number | null;
    Retractable?: boolean;
    Sold?: boolean;
    Bought?: boolean;
    PassePartoutAllowedResells?: number | null;
    PassePartoutAvailableResells?: number | null;
    PassePartoutLimitResell?: boolean;
    IbanRequired?: boolean;
    PassePartoutSold?: number | null;
    BundledAllowed?: boolean;
}

